<template>
  <div v-if="currentEpisode !== undefined" class="visualizer-episode">
    <div class="visualizer-episode__on-air">
      On Air
    </div>
    <div class="visualizer-episode__name">
      {{ currentEpisode.title }}
    </div>
    <div class="visualizer-episode__time">
      {{ duration }}
    </div>
    <div v-if="currentTrack && currentTrack !== currentEpisode.title" class="visualizer-episode__track">
      {{ currentTrack }}
    </div>
  </div>
</template>

<script setup lang="ts">
const { currentEpisode } = useCurrentProgramme()
const songData = ref()

fetchSongData()

const { resume, pause } = useIntervalFn(fetchSongData, 15000, { immediate: false })

onMounted(async (): void => {
  fetchSongData()
  resume()
})

const duration = computed(() => {
  if (currentEpisode.value === undefined) {
    return
  }
  const start = useFormatDate('utc', currentEpisode.value?.date.start)
  const end = useFormatDate('utc', currentEpisode.value?.date.end)
  return useFormatStartEndTime(start, end)
})

async function fetchSongData (): void {
  const data = await $fetch('/api/song')
  songData.value = data.data
}

const currentTrack = computed(() => {
  if (typeof songData.value === 'string') {
    return songData.value.replace('\n', '')
  }
  return ''
})

onUnmounted((): void => {
  pause()
})

</script>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.visualizer-episode {
  display: flex;
  flex-direction: column;
  gap: var(--space--s);
  margin-top: var(--space--s);

  &__on-air {
    @include tools.typo-heading-medium;
    display: flex;
    align-items: center;
    gap: var(--space--s);
    color: var(--color-background);
    -webkit-text-stroke: var(--border-width) var(--color-error);

    &::before {
      display: inline-flex;
      width: 32px;
      height: 32px;
      margin-top: 3px;
      background-color: var(--color-error);
      content: "";
      border-radius: 100%;
      filter: blur(2px);
      animation-name: pulse-live;
      animation-duration: 2.5s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: forwards;
      animation-timing-function: step-end;
    }
  }

  &__name {
    @include tools.typo-heading-medium;
  }

  &__track {
    display: flex;
    @include tools.to(tools.$breakpoint--desktop) {
      flex-direction: column;
      gap: var(--space--s);
    }
  }

  &__time {
    word-spacing: -0.125em;
    @include tools.from(tools.$breakpoint--desktop) {
      display: inline-block;
    }
  }
}

@include tools.pulse-live;

</style>
