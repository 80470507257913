<template>
  <div class="front-block-heading">
    <h2 class="front-block-heading__heading">
      {{ props.title }}
    </h2>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: ''
  }
})
</script>

<style lang="scss">
@use "~/assets/sass/tools";

  .front-block-heading {
    display: flex;
    margin-bottom: 0;
    padding: 0;

    &__heading {
      @include tools.typo-front-block-heading;
      margin: 0;
      color: var(--color-background);
      -webkit-text-stroke: var(--border-width) var(--color-text);
      hyphens: auto;
    }
  }
</style>
