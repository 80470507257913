<template>
  <article class="episode-current-programme">
    <NuxtLink class="episode-current-programme__inner" :to="path.alias">
      <div class="episode-current-programme__cover">
        <ResponsiveImage
          v-if="episodeImage !== undefined && episodeImage !== null"
          :image="episodeImage"
          class="episode-current-programme"
        />
        <PlaceholderImage v-else class="episode-current-programme__image" />
      </div>
      <div class="episode-current-programme__description">
        <p class="episode-current-programme__heading">
          {{ title }}
        </p>
        <div class="episode-current-programme__time">
          <div class="episode-current-programme__duration">
            {{ duration }}
          </div>
        </div>
      </div>
    </NuxtLink>
  </article>
</template>

<script setup lang="ts">

import { type EpisodeCurrentProgrammeFragment, type MediaImageFragment, type ShowTeaserFragment } from '#graphql-operations'

export interface Props {
  title?: string
  start?: string
  end?: string
  path?: {
    alias?: string
  }
  image?: MediaImageFragment | null,
  episode?: EpisodeCurrentProgrammeFragment | null,
  show?: ShowTeaserFragment | null,
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  start: '',
  end: '',
  path: () => {
    return { alias: '' }
  },
  image: null,
  episode: null,
  show: null
})

const episodeImage = computed(() => {
  return useEpisodeImageFallback(props?.image, props?.show?.image)
})

const duration = computed(() => {
  if (props.start && props.end) {
    const start = useFormatDate('utc', props.start)
    const end = useFormatDate('utc', props.end)
    return useFormatStartEndTime(start, end)
  }
  return ''
})

</script>

<style lang="scss">
@use "~/assets/sass/tools";

.episode-current-programme {
  &__inner {
    display: flex;
    align-items: center;
    gap: var(--space--sm);
    text-decoration: none;
  }

  &__cover {
    @include tools.square-image-crop;
    width: var(--front-episode-cover-size);
    min-width: var(--front-episode-cover-size);
    height: var(--front-episode-cover-size);
  }

  &__description {
    display: table;
    width: 100%;
  }

  &__heading {
    @include tools.typo-heading-medium;
    display: table-cell;
    max-width: 0;
    margin: 0;
    line-height: 1.3;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: 6px;
  }

  &__time {
    display: table-row;
    width: max-content;
    min-width: var(--episode-list-item-time-min-width);
    font-variant-numeric: tabular-nums;
    gap: var(--space--sm);
  }

  &__duration {
    word-spacing: -0.125em;
  }
}
</style>
