<template>
  <div class="visualizer">
    <div class="visualizer__inner">
      <div class="visualizer__background" :style="{ backgroundColor: colors.backgroundColor }" />
      <div
        ref="visualizer"
        class="visualizer__speaker"
        :class="classObject"
        :style="{ backgroundColor: colors.foregroundColor }"
      />
      <icon name="Logo" class="visualizer__logo" />
      <button
        class="visualizer__toggle"
        :aria-label="`Radio ${playerState.playing ? `pausieren` : `abspielen`}`"
        @click="togglePlayer"
      >
        <PlayerIcon class="visualizer__toggle-icon" />
      </button>
    </div>
    <div class="visualizer__episode">
      <ClientOnly>
        <VisualizerEpisode />
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRafFn } from '@vueuse/core'

const playerState = usePlayerState()
const appConfig = useAppConfig()
const { togglePlayer } = usePlayer()

interface Colors {
  backgroundColor: string
  foregroundColor: string
}

const colors: Ref<Colors> = ref({
  backgroundColor: 'transparent',
  foregroundColor: 'transparent'
})

const player = ref(null)
const visualizer = ref(null)
let visualizerValue: number = 1
const visualizerImpendance: number = 3
const visualizerImpendanceNegative: number = 1 - (1 / visualizerImpendance)
const isBrokenUserAgent: boolean = ref(false)

const classObject = computed(() => {
  return {
    'visualizer__speaker--random': isBrokenUserAgent.value
  }
})

const ctx: Ref<AudioContext | null> = useState('AudioContext', () => null)
const analyser: Ref<AnalyserNode | null> = useState('AnalyserNode', () => null)
const src: Ref<MediaElementAudioSourceNode | null> = useState('MediaElementAudioSourceNode', () => null)
const fftSize = 1024
const data = new Uint8Array(fftSize / 2)
let sampleRate = 48000

const { pause, resume } = useRafFn(() => {
  const value = getAnalyserData()
  visualizer.value.style.setProperty('transform', `scale(${value.toString()})`)
}, { immediate: false })

onMounted((): void => {
  setColors()

  const userAgent = window.navigator.userAgent
  const isFirefoxMobile = (userAgent.includes('Gecko') && userAgent.includes('Mobile;') && userAgent.includes('Android'))
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)

  isBrokenUserAgent.value = isSafari

  if (isFirefoxMobile) {
    sampleRate = 44100
  }
  player.value = document.querySelector('#stream')

  if (playerState.value.playing) {
    initialize()
  }
})

onActivated((): void => {
  setColors()
})

onUnmounted((): void => {
  destroy()
})

function initialize () {
  if (!isBrokenUserAgent.value) {
    initializeAudioAnalyser()
  }
  resume()
}

watch(
  () => playerState.value.playing,
  (playing) => {
    if (playing) {
      initialize()
    } else {
      destroy()
    }
  }
)

function initializeAudioAnalyser () {
  const audio = resolveUnref(player)
  if (!audio) { return }
  if (ctx.value === null) {
    ctx.value = new AudioContext({ latencyHint: 'playback', sampleRate })
  }
  if (src.value === null) {
    src.value = ctx.value.createMediaElementSource(audio)
  }
  if (analyser.value === null) {
    analyser.value = ctx.value.createAnalyser()
    analyser.value.fftSize = fftSize
    analyser.value.smoothingTimeConstant = 0.7
    src.value?.connect(analyser.value)
    analyser.value.connect(ctx.value.destination)
    ctx.value.resume()
  }
}

function average (array: Uint8Array): number {
  return array.reduce((a, b) => a + b) / array.length
}

function getAnalyserData (): number {
  if (isBrokenUserAgent.value) {
    const random = Math.random()
    if (Math.round(random * 100) % 6 === 0) {
      visualizerValue = 0.8 + (random * 0.2)
    }
  } else if (
    !analyser.value ||
    !visualizer.value) {
    visualizerValue = 1
  } else {
    analyser.value.getByteFrequencyData(data)
    const max = average(data.slice(2, 8))

    visualizerValue = ((max / 255) / visualizerImpendance) + visualizerImpendanceNegative
  }
  return visualizerValue
}

function destroy () {
  pause()
}

function setColors () {
  const currentColors = appConfig.colors
  const backgroundColorIndex = randomColorIndex(currentColors)
  colors.value.backgroundColor = currentColors.splice(backgroundColorIndex, 1)[0]
  const foregroundColorIndex = randomColorIndex(currentColors)
  colors.value.foregroundColor = currentColors.splice(foregroundColorIndex, 1)[0]
}

function randomColorIndex (colors: string[]) {
  return Math.floor(Math.random() * colors.length)
}

useHead({
  link: [
    {
      rel: 'preload',
      as: 'image',
      type: 'image/png',
      crossorigin: '',
      href: '/images/player-background.png'
    },
    {
      rel: 'preload',
      as: 'image',
      type: 'image/png',
      crossorigin: '',
      href: '/images/player-speaker.png'
    }
  ]
})

</script>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.visualizer {
  --visualizer-episode-height: 120px;
  margin-bottom: calc(var(--visualizer-episode-height) + var(--space--l));

  &__inner {
    position: relative;
  }

  &__background {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 60svmin;
    transform: translateY(-50%);
    z-index: -1;
    background-color: #207DFF;
    mask-image: url('/images/player-background.png');
    mask-size: cover;
  }

  &__speaker {
    height: 80svmin;
    margin: 0 auto;
    background-color: #FF4600;
    aspect-ratio: 1 / 1;
    mask-image: url('/images/player-speaker.png');
    mask-size: contain;
    will-change: transform;

    &--random {
      transition: 0.1s transform linear;
    }
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 58svmin;
    height: 58svmin;
    transform: translate(-50%, -50%);
  }

  &__toggle {
    @include tools.center;
    width: 25svmin;
    height: 25svmin;
    @include tools.button-reset-styles;
  }

  &__episode {
    position: absolute;
    height: var(--visualizer-episode-height);
    margin-top: calc(var(--space--m) * -1);
  }
}
</style>

<style lang="scss">
@use "~/assets/sass/tools";

.visualizer {
  &__logo {
    &.nuxt-icon.icon {
      svg {
        position: relative;
        top: var(--logo-offset--vertical);
        right: var(--logo-offset--horizontal);
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
