<template>
  <div class="front">
    <Visualizer />
    <PageContent>
      <section class="front__section front__section--programme">
        <CurrentProgramme />
      </section>
      <section class="front__section front__section--news">
        <FrontBlockHeading title="Aktuell" />
        <NewsCollection v-if="data && data.news" :items="data.news.items" />
        <div class="front__cta">
          <NuxtLink to="/aktuell" class="front__cta-link">
            zu allen LoRa-News
          </NuxtLink>
        </div>
      </section>

      <section class="front__section front__section--lora-info">
        <FrontBlockHeading title="Redaktionen" />
        <BlockContent v-if="frontEditorial" :content="frontEditorial" />
      </section>

      <section class="front__section front__section--lora-info">
        <FrontBlockHeading title="LoRa Info" />
        <LoraInfoCollection v-if="data && data.info" :items="data.info.items" />
        <div class="front__cta">
          <NuxtLink to="/ueber-uns/lora-info" class="front__cta-link">
            zu allen LoRa Info
          </NuxtLink>
        </div>
      </section>

      <section class="front__section front__section--lora-info">
        <FrontBlockHeading title="Mitmachen" />
        <BlockContent v-if="frontInvolve" :content="frontInvolve" />
      </section>
    </PageContent>
  </div>
</template>
<script setup>
async function fetchData () {
  return await useGraphqlQuery('front').then((v) => {
    return v.data
  })
}

const { data } = await useAsyncData('front', async () => {
  return await fetchData(false)
})

const { data: frontEditorial } = await useGetContentBlock('782467d6-ee84-4494-a5bf-0551196abe1c', 'frontEditorial')
const { data: frontInvolve } = await useGetContentBlock('492ed96a-28b3-4ce7-953d-74a1823badf1', 'frontInvolve')

</script>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.front {
  &__section {
    display: flex;
    flex-direction: column;
    gap: var(--grid-gutter);
    margin-bottom: var(--space--xxl);

    .news-collection,
    .lora-info {
      margin-top: 0;
      margin-bottom: 0;
    }

    .blocks {
      row-gap: calc(var(--grid-gutter) * 2);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
  }

  &__cta-link {
    text-decoration: none;
    @include tools.button;
  }

  &__programme-placeholder {
    height: 800px;
  }
}
</style>
