<template>
  <section class="current-programme">
    <aside class="current-programme__content">
      <FrontBlockHeading title="Programm" />
      <div class="current-programme__items">
        <EpisodeCurrentProgramme
          v-for="(item, index) in upcomingEpisodes"
          :key="index"
          class="current-programme__episode"
          :episode="item"
          :show="item.show"
          :image="item.image"
          :title="item.title"
          :start="item.date?.start"
          :end="item.date?.end"
          :path="item.path"
        />
      </div>
      <div class="current-programme__link">
        <NuxtLink to="/radio/programm" class="current-programme__link-item">
          zum Programm
        </NuxtLink>
      </div>
    </aside>
    <aside class="current-programme__content">
      <FrontBlockHeading title="Audiothek" />
      <div class="current-programme__items">
        <EpisodeCurrentProgramme
          v-for="item in pastEpisodes"
          :key="item.uuid"
          class="current-programme__episode"
          :episode="item"
          :show="item.show"
          :image="item.image"
          :title="item.title"
          :start="item.date?.start"
          :end="item.date?.end"
          :path="item.path"
        />
      </div>
      <div class="current-programme__link">
        <NuxtLink to="/radio/audiothek" class="current-programme__link-item">
          zur Audiothek
        </NuxtLink>
      </div>
    </aside>
  </section>
</template>

<script setup lang="ts">
const data = ref()

onNuxtReady(() => {
  data.value = useCurrentProgramme()
})

const emptyEpisode = {
  uuid: null,
  show: null,
  image: null,
  title: null,
  start: null,
  end: null,
  path: {
    alias: null
  }
}

const emptyEpisodes = [emptyEpisode, emptyEpisode, emptyEpisode]

const upcomingEpisodes = computed(() => {
  if (data?.value?.upcomingEpisodes) {
    return data.value.upcomingEpisodes.slice(0, 3)
  }
  return emptyEpisodes
})

const pastEpisodes = computed(() => {
  if (data?.value?.pastEpisodes) {
    return data.value.pastEpisodes.slice(0, 3)
  }
  return emptyEpisodes
})
</script>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.current-programme {
  @include tools.grid($columns: 2);
  row-gap: var(--space--l);

  @include tools.to(tools.$breakpoint--desktop) {
    @include tools.grid($columns: 1);
    row-gap: var(--space--l);
  }

  &__content,
  &__items {
    display: flex;
    flex-direction: column;
    gap: var(--grid-gutter);
  }

  &__items {
    height: calc((var(--front-episode-cover-size) * 3) + (var(--grid-gutter) * 2));
  }

  &__link {
    display: flex;

    @include tools.to(tools.$breakpoint--desktop) {
      justify-content: center;
    }
  }

  &__link-item {
    @include tools.button;
  }
}
</style>
